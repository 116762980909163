import { createSlice } from '@reduxjs/toolkit';
import createDebouncedAsyncThunk from 'lib/store/create-debounced-async-thunk';
import { CountriesData } from './model/countries-models';
import { getCountries as getCountriesApi } from './countries-api';

interface InitialStateModel {
  countriesData: CountriesData;
  countriesStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: InitialStateModel = {
  countriesData: [],
  countriesStatus: 'idle',
  error: null,
};

export const getCountries = createDebouncedAsyncThunk(
  'countries/getCountries',
  async () => getCountriesApi(),
  150
);

export const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCountries.pending, (state) => {
        state.countriesStatus = 'loading';
      })
      .addCase(getCountries.fulfilled, (state, action) => {
        state.countriesStatus = 'succeeded';
        const countriesArray = action.payload.data;
        const sortedCountryRegionsArray = countriesArray.map((country) => {
          country.regions?.sort((a, b) =>
            a.regionName.normalize().localeCompare(b.regionName.normalize())
          );
          return country;
        });
        const sortedCountriesArray = sortedCountryRegionsArray.sort((a, b) =>
          a.countryName.normalize().localeCompare(b.countryName.normalize())
        );
        state.countriesData = sortedCountriesArray;
      })
      .addCase(getCountries.rejected, (state, action) => {
        state.countriesStatus = 'failed';
        state.error = action.error.message || 'Something went wrong';
      });
  },
});

export default countriesSlice.reducer;
