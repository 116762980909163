import { CustomerData } from 'lib/commerce/customer/model/customer-models';
import { dataLayerPush } from './data-layer-orchestrator';

export const pushCoreDataLayer = (
  loggedIn: string,
  currency: string,
  locale: string,
  customerInfo?: CustomerData
): void => {
  dataLayerPush({
    dataLayer: {
      event: 'dataLayer_initialized',
      logged_in: loggedIn,
      currency: currency,
      translated_language: locale,
      ...(customerInfo && {
        user_id: customerInfo?.contactId,
        customer_type: customerInfo.customerType,
        customer_interest: customerInfo.customerInterest,
      }),
    },
  });
};
