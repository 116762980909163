import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import createDebouncedAsyncThunk from 'lib/store/create-debounced-async-thunk';
import { getOrderDetails as getOrderDetailsApi } from './order-api';
import { GetOrderDetailsData } from './order-models';

interface InitialStateModel {
  orderDetailsData: GetOrderDetailsData | null;
  orderLicensingDetailsData: GetOrderDetailsData | null;
  orderDetailsStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  orderLicensingDetailsStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: InitialStateModel = {
  orderDetailsData: null,
  orderLicensingDetailsData: null,
  orderDetailsStatus: 'idle',
  orderLicensingDetailsStatus: 'idle',
  error: null,
};

export const getOrderDetails = createDebouncedAsyncThunk(
  'checkout/getOrderDetails',
  async (data: { orderSummaryId: string; bearerToken: string }) => {
    return await getOrderDetailsApi(data.orderSummaryId, data.bearerToken);
  },
  150
);

export const getLicensingDetails = createAsyncThunk(
  'checkout/getLicensingDetails',
  async (data: { orderSummaryId: string; bearerToken: string }) => {
    const results = await getOrderDetailsApi(data.orderSummaryId, data.bearerToken);
    const missingLicensing =
      results.data.orderItems.filter(
        (x) => x.product.isSubscription && x.licensingComplete == false
      ).length > 0;
    if (missingLicensing) {
      throw new Error('Missing Licensing Details');
    }

    return results;
  }
);

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getOrderDetails.pending, (state) => {
        state.orderDetailsStatus = 'loading';
      })
      .addCase(getOrderDetails.fulfilled, (state, action) => {
        state.orderDetailsData = action.payload.data;
        state.orderDetailsStatus = 'succeeded';
      })
      .addCase(getOrderDetails.rejected, (state, action) => {
        state.orderDetailsStatus = 'failed';
        state.error = action.error.message || 'Something went wrong';
      })
      .addCase(getLicensingDetails.pending, (state) => {
        state.orderLicensingDetailsStatus = 'loading';
      })
      .addCase(getLicensingDetails.fulfilled, (state, action) => {
        state.orderLicensingDetailsData = action.payload.data;
        state.orderLicensingDetailsStatus = 'succeeded';
      })
      .addCase(getLicensingDetails.rejected, (state) => {
        state.orderLicensingDetailsStatus = 'failed';
        //state.error = action.error.message || 'Something went wrong';
      });
  },
});

export default orderSlice.reducer;
