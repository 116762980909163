import { dataLayerPush } from './data-layer-orchestrator';

export const pushVirtualPageView = (
  pagePath: string,
  pageTitle: string,
  fullPagePath: string
): void => {
  dataLayerPush({
    dataLayer: {
      event: 'virtual_page_view',
      page_path: pagePath,
      page_title: pageTitle,
      original_location: fullPagePath,
    },
  });
};
