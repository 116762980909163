import { isNonOkResponse } from 'lib/common/common-http-client';
import { SfResponse } from 'lib/common/model/common-models';
import { commerceGet } from '../common/base-commerce-api';
import { CountriesData } from './model/countries-models';

export const getCountries = async (): Promise<SfResponse<CountriesData>> => {
  const response = await commerceGet<SfResponse<CountriesData>>(
    '/services/apexrest/countries',
    undefined,
    ''
  );

  if (isNonOkResponse(response)) {
    throw new Error('Failed to get countries data!');
  }

  return response;
};
