import { createSlice } from '@reduxjs/toolkit';
import createDebouncedAsyncThunk from 'lib/store/create-debounced-async-thunk';
import { createCheckoutSession } from '../checkout/checkout-slice';
import {
  deleteStoredDefaultCurrency,
  getStoredCurrency,
  getStoredDefaultCurrency,
  hasStoredCurrency,
  hasStoredDefaultCurrency,
  setStoredCurrency,
} from './cart-provider';
import {
  getCartItems,
  addCartItem as addCartItemApi,
  updateCartItem as updateCartItemApi,
  deleteCartItem as deleteCartItemApi,
  getCartSummary as getCartSummaryApi,
  changeCurrency as changeCurrencyApi,
  applyCoupon as applyCouponAPI,
  removeCoupon as removeCouponAPI,
} from './cart-api';
import { CartSummary, CouponDetails, GetCartItemsData } from './cart-models';

export interface InitialStateModel {
  cartItemsData: GetCartItemsData | null;
  cartItemsDataStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  cartSummaryDataStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  cartAddItemStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  cartUpdateItemStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  cartDeleteItemStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  applyCouponStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  cartSummaryData: CartSummary | null;
  couponData: CouponDetails | null;
  currencyIsoCode: string | null;
  currencyIsoCodeStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  cartPromotionsStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const storedCurrency = getStoredCurrency();

const initialState: InitialStateModel = {
  cartItemsData: null,
  cartItemsDataStatus: 'idle',
  cartSummaryDataStatus: 'idle',
  cartAddItemStatus: 'idle',
  cartUpdateItemStatus: 'idle',
  cartDeleteItemStatus: 'idle',
  applyCouponStatus: 'idle',
  cartSummaryData: null,
  couponData: null,
  currencyIsoCode: storedCurrency.length > 0 ? storedCurrency : getStoredDefaultCurrency(),
  currencyIsoCodeStatus: 'idle',
  cartPromotionsStatus: 'idle',
  error: null,
};

export const fetchCartItems = createDebouncedAsyncThunk(
  'cart/getCartItems',
  async (data: { bearerToken: string }, thunkApi) => {
    let response = await getCartItems(data.bearerToken);

    const geoBasedCurrency = getStoredDefaultCurrency();
    if (geoBasedCurrency != null && !hasStoredCurrency()) {
      if (response.data.cartSummary.currencyIsoCode === geoBasedCurrency) {
        setStoredCurrency(geoBasedCurrency);
        deleteStoredDefaultCurrency();
        return response;
      }

      await thunkApi.dispatch(
        changeCurrency({ currencyIsoCode: geoBasedCurrency, bearerToken: data.bearerToken })
      );
      deleteStoredDefaultCurrency();
      response = await getCartItems(data.bearerToken);
    }

    return response;
  },
  300
);

export const addCartItem = createDebouncedAsyncThunk(
  'cart/addCartItem',
  async (data: { sku: string; quantity: number; bearerToken: string }, thunkApi) => {
    const response = await addCartItemApi(data.sku, data.quantity, data.bearerToken);
    thunkApi.dispatch(fetchCartItems({ bearerToken: data.bearerToken }));
    return response;
  },
  150
);

export const updateCartItem = createDebouncedAsyncThunk(
  'cart/updateCartItem',
  async (data: { cartItemId: string; quantity: number; bearerToken: string }, thunkApi) => {
    try {
      const response = await updateCartItemApi(data.cartItemId, data.quantity, data.bearerToken);
      thunkApi.dispatch(fetchCartItems({ bearerToken: data.bearerToken }));
      return response;
    } catch {
      thunkApi.dispatch(fetchCartItems({ bearerToken: data.bearerToken }));
      return null;
    }
  },
  150
);

export const deleteCartItem = createDebouncedAsyncThunk(
  'cart/deleteCartItem',
  async (data: { cartItemId: string; bearerToken: string }, thunkApi) => {
    const response = await deleteCartItemApi(data.cartItemId, data.bearerToken);
    thunkApi.dispatch(fetchCartItems({ bearerToken: data.bearerToken }));
    return response;
  },
  150
);

export const getCartSummary = createDebouncedAsyncThunk(
  'cart/getCartSummary',
  async (data: { bearerToken: string }, thunkApi) => {
    let response = await getCartSummaryApi(data.bearerToken);

    const geoBasedCurrency = getStoredDefaultCurrency();
    if (geoBasedCurrency != null && !hasStoredCurrency()) {
      if (response.data.currencyIsoCode === geoBasedCurrency) {
        setStoredCurrency(geoBasedCurrency);
        deleteStoredDefaultCurrency();
        return response;
      }

      await thunkApi.dispatch(
        changeCurrency({ currencyIsoCode: geoBasedCurrency, bearerToken: data.bearerToken })
      );
      deleteStoredDefaultCurrency();
      response = await getCartSummaryApi(data.bearerToken);
    }

    return response;
  },
  150
);

export const changeCurrency = createDebouncedAsyncThunk(
  'currency/changeCurrency',
  async (data: { currencyIsoCode: string; bearerToken: string }) => {
    return await changeCurrencyApi(data.currencyIsoCode, data.bearerToken);
  },
  150
);

export const applyCoupon = createDebouncedAsyncThunk(
  'cart/applyCoupon',
  async (data: { couponCode: string; bearerToken: string }, thunkApi) => {
    const response = await applyCouponAPI(data.couponCode, data.bearerToken);
    thunkApi.dispatch(fetchCartItems({ bearerToken: data.bearerToken }));
    return response;
  },
  150
);

export const removeCoupon = createDebouncedAsyncThunk(
  'cart/removeCoupon',
  async (data: { cartCouponId: string; bearerToken: string }, thunkApi) => {
    const response = await removeCouponAPI(data.cartCouponId, data.bearerToken);
    thunkApi.dispatch(fetchCartItems({ bearerToken: data.bearerToken }));
    return response;
  },
  150
);

const getImmediateCurrencyIsoCode = (): string | null => {
  if (hasStoredDefaultCurrency() && !hasStoredCurrency()) {
    return getStoredDefaultCurrency();
  }

  if (hasStoredCurrency()) {
    return getStoredCurrency();
  }

  return null;
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCartItems.pending, (state) => {
        state.cartItemsDataStatus = 'loading';
        if (state.currencyIsoCode != null) {
          return;
        }

        state.currencyIsoCode = getImmediateCurrencyIsoCode();
        state.currencyIsoCodeStatus = 'succeeded';
      })
      .addCase(fetchCartItems.fulfilled, (state, action) => {
        state.cartItemsDataStatus = 'succeeded';
        state.cartItemsData = action.payload.data;
        state.cartSummaryData = action.payload.data?.cartSummary;
        state.cartSummaryDataStatus = 'succeeded';
        const isoCode = action.payload.data.cartSummary.currencyIsoCode;
        state.currencyIsoCode = isoCode;
        setStoredCurrency(isoCode);
        state.currencyIsoCodeStatus = 'succeeded';
      })
      .addCase(fetchCartItems.rejected, (state, action) => {
        state.cartItemsDataStatus = 'failed';
        state.error = action.error.message || 'Something went wrong';
      })
      .addCase(addCartItem.pending, (state) => {
        state.cartItemsDataStatus = 'loading';
        state.cartAddItemStatus = 'loading';
      })
      .addCase(addCartItem.fulfilled, (state) => {
        state.cartAddItemStatus = 'succeeded';
      })
      .addCase(addCartItem.rejected, (state) => {
        state.cartAddItemStatus = 'failed';
      })
      .addCase(updateCartItem.pending, (state) => {
        state.cartItemsDataStatus = 'loading';
        state.cartUpdateItemStatus = 'loading';
      })
      .addCase(updateCartItem.fulfilled, (state) => {
        state.cartUpdateItemStatus = 'succeeded';
      })
      .addCase(updateCartItem.rejected, (state) => {
        state.cartUpdateItemStatus = 'failed';
      })
      .addCase(deleteCartItem.pending, (state) => {
        state.cartItemsDataStatus = 'loading';
        state.cartDeleteItemStatus = 'loading';
      })
      .addCase(deleteCartItem.fulfilled, (state) => {
        state.cartDeleteItemStatus = 'succeeded';
      })
      .addCase(deleteCartItem.rejected, (state) => {
        state.cartDeleteItemStatus = 'failed';
      })
      .addCase(getCartSummary.pending, (state) => {
        state.cartSummaryDataStatus = 'loading';

        if (state.currencyIsoCode != null) {
          return;
        }
        state.currencyIsoCode = getImmediateCurrencyIsoCode();
        state.currencyIsoCodeStatus = 'succeeded';
      })
      .addCase(getCartSummary.fulfilled, (state, action) => {
        state.cartSummaryDataStatus = 'succeeded';
        state.cartSummaryData = action.payload.data;
        const isoCode = action.payload.data.currencyIsoCode;
        state.currencyIsoCode = isoCode;
        setStoredCurrency(isoCode);
        state.currencyIsoCodeStatus = 'succeeded';
      })
      .addCase(getCartSummary.rejected, (state) => {
        state.cartSummaryDataStatus = 'failed';
      })
      .addCase(changeCurrency.pending, (state) => {
        state.cartItemsDataStatus = 'loading';
        state.currencyIsoCodeStatus = 'loading';
      })
      .addCase(changeCurrency.fulfilled, (state, action) => {
        state.cartItemsDataStatus = 'succeeded';
        const newIsoCode = action.payload.data.newCurrencyIsoCode;
        state.currencyIsoCodeStatus = 'succeeded';
        state.currencyIsoCode = newIsoCode;
        setStoredCurrency(newIsoCode);
      })
      .addCase(changeCurrency.rejected, (state, action) => {
        state.currencyIsoCodeStatus = 'failed';
        state.error = action.error.message || 'Something went wrong';
      })
      .addCase(createCheckoutSession.fulfilled, (state, action) => {
        const isoCode = action.payload.data.digitalRiverResponse.currency;
        state.currencyIsoCode = isoCode;
        setStoredCurrency(isoCode);
      })
      .addCase(applyCoupon.pending, (state) => {
        state.applyCouponStatus = 'loading';
      })
      .addCase(applyCoupon.fulfilled, (state, action) => {
        state.couponData = action.payload.data;
        state.applyCouponStatus = 'succeeded';
      })
      .addCase(applyCoupon.rejected, (state, action) => {
        state.applyCouponStatus = 'failed';
        state.error = action.error.message || 'Something went wrong';
      })
      .addCase(removeCoupon.pending, (state) => {
        state.applyCouponStatus = 'loading';
      })
      .addCase(removeCoupon.fulfilled, (state) => {
        state.applyCouponStatus = 'idle';
      })
      .addCase(removeCoupon.rejected, (state, action) => {
        state.error = action.error.message || 'Something went wrong';
      });
  },
});

export default cartSlice.reducer;
