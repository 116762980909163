import { commonRequest } from 'lib/common/common-http-client';
import { NonOkResponse } from '../../common/model/common-models';

export const commerceGet = async <TSuccess>(
  path: string,
  queryParams: Record<string, string> = {},
  bearerToken: string
): Promise<TSuccess | NonOkResponse> => {
  return await commerceRequest('GET', path, null, queryParams, bearerToken);
};

export const commercePost = async <TSuccess>(
  path: string,
  body: Record<string, unknown>,
  queryParams: Record<string, string> = {},
  bearerToken: string
): Promise<TSuccess | NonOkResponse> => {
  return await commerceRequest('POST', path, body, queryParams, bearerToken);
};

export const commercePatch = async <TSuccess>(
  path: string,
  body: Record<string, unknown>,
  queryParams: Record<string, string> = {},
  bearerToken: string
): Promise<TSuccess | NonOkResponse> => {
  return await commerceRequest('PATCH', path, body, queryParams, bearerToken);
};

export const commerceDelete = async <TSuccess>(
  path: string,
  body: Record<string, unknown>,
  queryParams: Record<string, string> = {},
  bearerToken: string
): Promise<TSuccess | NonOkResponse> => {
  return await commerceRequest('DELETE', path, body, queryParams, bearerToken);
};

export const commerceRequest = async <TSuccess>(
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  path: string,
  body: Record<string, unknown> | null,
  queryParams: Record<string, string> = {},
  bearerToken: string
): Promise<TSuccess | NonOkResponse> => {
  return await commonRequest(method, path, body, queryParams, bearerToken);
};
