import TagManager, { DataLayerArgs } from 'react-gtm-module';

let initialized = false;
let queue: DataLayerArgs[] = [];

export const dataLayerPush = (dataLayerArgs: DataLayerArgs): void => {
  const dataLayer = dataLayerArgs?.dataLayer as Record<string, unknown>;

  if (initialized) {
    TagManager.dataLayer(dataLayerArgs);
    return;
  }

  if (dataLayer?.event == 'dataLayer_initialized') {
    initialized = true;
    TagManager.dataLayer(dataLayerArgs);

    for (const event in queue) {
      TagManager.dataLayer(queue[event]);
    }

    queue = [];
    return;
  }

  queue.push(dataLayerArgs);
};
