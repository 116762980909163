import type { AppProps } from 'next/app';
import { I18nProvider } from 'next-localization';
import { SitecorePageProps } from 'lib/page-props';
import { SessionProvider } from 'next-auth/react';
import store from '../lib/store/store';
import { Provider } from 'react-redux';
import NextNProgress from 'nextjs-progressbar';

/*Hydration bug https://github.com/FortAwesome/Font-Awesome/issues/19348 */
/* eslint-disable @typescript-eslint/no-var-requires */
const { library } = require('@fortawesome/fontawesome-svg-core');
library.autoAddCss = false;
import { fa0 } from '@fortawesome/free-solid-svg-icons/fa0';
import { fa1 } from '@fortawesome/free-solid-svg-icons/fa1';
import { fa2 } from '@fortawesome/free-solid-svg-icons/fa2';
import { fa3 } from '@fortawesome/free-solid-svg-icons/fa3';
import { fa4 } from '@fortawesome/free-solid-svg-icons/fa4';
import { fa5 } from '@fortawesome/free-solid-svg-icons/fa5';
import { fa6 } from '@fortawesome/free-solid-svg-icons/fa6';
import { fa7 } from '@fortawesome/free-solid-svg-icons/fa7';
import { fa8 } from '@fortawesome/free-solid-svg-icons/fa8';
import { fa9 } from '@fortawesome/free-solid-svg-icons/fa9';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons/faAsterisk';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faFileLines } from '@fortawesome/free-solid-svg-icons/faFileLines';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faCircleArrowUp } from '@fortawesome/free-solid-svg-icons/faCircleArrowUp';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons/faCircleInfo';
import { faClipboard } from '@fortawesome/free-solid-svg-icons/faClipboard';
import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons/faClipboardCheck';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons/faMagnifyingGlass';
import { faSchool } from '@fortawesome/free-solid-svg-icons/faSchool';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faBookOpenReader } from '@fortawesome/free-solid-svg-icons/faBookOpenReader';
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons/faCirclePlay';
import { faAward } from '@fortawesome/free-solid-svg-icons/faAward';
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons/faGraduationCap';
import { faHouse } from '@fortawesome/free-solid-svg-icons/faHouse';
import { faSquareRss } from '@fortawesome/free-solid-svg-icons/faSquareRss';
import { faMugSaucer } from '@fortawesome/free-solid-svg-icons/faMugSaucer';
import { faChalkboardUser } from '@fortawesome/free-solid-svg-icons/faChalkboardUser';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons/faCartShopping';
import { faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons/faScrewdriverWrench';
import { faMusic } from '@fortawesome/free-solid-svg-icons/faMusic';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons/faQuoteLeft';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faBlog } from '@fortawesome/free-solid-svg-icons/faBlog';
import { faCertificate } from '@fortawesome/free-solid-svg-icons/faCertificate';
import { faTable } from '@fortawesome/free-solid-svg-icons/faTable';
import { faHandsHelping } from '@fortawesome/free-solid-svg-icons/faHandsHelping';
import { faHandshakeSimple } from '@fortawesome/free-solid-svg-icons/faHandshakeSimple';
import { faPeopleArrows } from '@fortawesome/free-solid-svg-icons/faPeopleArrows';
import { faInfo } from '@fortawesome/free-solid-svg-icons/faInfo';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faUserGraduate } from '@fortawesome/free-solid-svg-icons/faUserGraduate';
import { faUniversity } from '@fortawesome/free-solid-svg-icons/faUniversity';
import { faFileContract } from '@fortawesome/free-solid-svg-icons/faFileContract';
import { faCheckDouble } from '@fortawesome/free-solid-svg-icons/faCheckDouble';
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload';
import { faNewspaper } from '@fortawesome/free-solid-svg-icons/faNewspaper';
import { faBroadcastTower } from '@fortawesome/free-solid-svg-icons/faBroadcastTower';
import { faPodcast } from '@fortawesome/free-solid-svg-icons/faPodcast';
import { faQuestion } from '@fortawesome/free-solid-svg-icons/faQuestion';
import { faTools } from '@fortawesome/free-solid-svg-icons/faTools';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons/faExchangeAlt';
import { faExchange } from '@fortawesome/free-solid-svg-icons/faExchange';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons/faCheckSquare';
import { faChartSimple } from '@fortawesome/free-solid-svg-icons/faChartSimple';
import { faGear } from '@fortawesome/free-solid-svg-icons/faGear';
import { faPersonChalkboard } from '@fortawesome/free-solid-svg-icons/faPersonChalkboard';
import { faComputer } from '@fortawesome/free-solid-svg-icons/faComputer';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons/faCircleQuestion';

import '@fortawesome/fontawesome-svg-core/styles.css';
import '../styles/app.css';
import '../styles/global.css';
import '../styles/marketo.css';
import 'react-multi-carousel/lib/styles.css';
import CoreDataLayer from 'components/foundation/non-sitecore/seo/CoreDataLayer';
import { SpeedInsights } from '@vercel/speed-insights/next';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import Head from 'next/head';
import React from 'react';

const iconsToUse = [
  fa0,
  fa1,
  fa2,
  fa3,
  fa4,
  fa5,
  fa6,
  fa7,
  fa8,
  fa9,
  faAsterisk,
  faCheck,
  faFileLines,
  faAngleLeft,
  faAngleRight,
  faChevronLeft,
  faChevronRight,
  faMagnifyingGlass,
  faUsers,
  faSchool,
  faCircleInfo,
  faCircle,
  faClipboard,
  faClipboardCheck,
  faCircleArrowUp,
  faBookOpenReader,
  faCirclePlay,
  faAward,
  faBook,
  faGraduationCap,
  faHouse,
  faSquareRss,
  faMugSaucer,
  faChalkboardUser,
  faUser,
  faCartShopping,
  faScrewdriverWrench,
  faMusic,
  faQuoteLeft,
  faDownload,
  faBlog,
  faCertificate,
  faTable,
  faHandsHelping,
  faHandshakeSimple,
  faPeopleArrows,
  faInfo,
  faInfoCircle,
  faCheckCircle,
  faUserGraduate,
  faUniversity,
  faFileContract,
  faCheckDouble,
  faUpload,
  faNewspaper,
  faBroadcastTower,
  faPodcast,
  faQuestion,
  faTools,
  faExchangeAlt,
  faExchange,
  faCheckSquare,
  faChartSimple,
  faGear,
  faPersonChalkboard,
  faComputer,
  faCircleQuestion,
];

function App({ Component, pageProps }: AppProps<SitecorePageProps>): JSX.Element {
  const { dictionary, ...rest } = pageProps;
  library.add(iconsToUse);

  return (
    <SessionProvider
      session={pageProps.session}
      refetchOnWindowFocus={false}
      refetchInterval={22 * 60 * 60} // 22 hours
    >
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5" />
      </Head>

      <Provider store={store}>
        <I18nProvider lngDict={dictionary} locale={pageProps.locale}>
          <>
            <NextNProgress />
            <CoreDataLayer
              locale={pageProps.locale}
              pageTitle={
                (pageProps.layoutData.sitecore.route?.fields?.metaPageTitle as Field<string>).value
              }
            />
            <Component {...rest} />
            <SpeedInsights sampleRate={0.1} />
          </>
        </I18nProvider>
      </Provider>
    </SessionProvider>
  );
}

export default App;
