import { isNonOkResponse } from 'lib/common/common-http-client';
import { GetOrderDetailsData } from './order-models';
import { OrderConfirmationSurveyItem } from 'src/pages/api/order/models/order-confirmation-survey';
import { stringify } from 'querystring';
import { commerceGet, commercePost } from 'lib/commerce/common/base-commerce-api';
import { SfResponse } from 'lib/common/model/common-models';

export const sendEmail = async (
  orderSummaryId: string,
  bearerToken: string
): Promise<SfResponse<unknown>> => {
  const response = await commercePost<SfResponse<unknown>>(
    '/order/send-email',
    {
      orderSummaryId: orderSummaryId,
    },
    undefined,
    bearerToken
  );

  if (isNonOkResponse(response)) {
    if (response.status == 403) {
      throw new Error('You do not have access.');
    }

    throw new Error('Failed to get order');
  }

  return response;
};

export const getOrderDetails = async (
  orderSummaryId: string,
  bearerToken: string
): Promise<SfResponse<GetOrderDetailsData>> => {
  const response = await commerceGet<SfResponse<GetOrderDetailsData>>(
    '/order/details',
    {
      orderSummaryId: orderSummaryId,
    },
    bearerToken
  );

  if (isNonOkResponse(response)) {
    if (response.status == 403) {
      throw new Error('You do not have access.');
    }

    throw new Error('Failed to get order');
  }

  return response;
};

export const getSitecoreOrderSurvey = async (
  productItemId: string,
  language: string
): Promise<OrderConfirmationSurveyItem[] | null> => {
  const queryParams: Record<string, string> = {
    productItemId,
    language,
  };

  const queryParamsString = stringify(queryParams);
  const response = await fetch(`/api/order/survey?${queryParamsString}`, {
    method: 'GET',
  });

  if (!response.ok) {
    return null;
  }

  const responseData = await response.text();

  try {
    const data = JSON.parse(responseData);
    const result = data as OrderConfirmationSurveyItem[];
    return result;
  } catch {
    //may need to change later
    return responseData as unknown as OrderConfirmationSurveyItem[];
  }
};
