import { createSlice } from '@reduxjs/toolkit';
import createDebouncedAsyncThunk from 'lib/store/create-debounced-async-thunk';
import { getCustomerInfo as getCustomerInfoApi } from './customer-api';
import { CustomerData } from './model/customer-models';

interface InitialStateModel {
  customerData: CustomerData | null;
  customerDataStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: InitialStateModel = {
  customerData: null,
  customerDataStatus: 'idle',
  error: null,
};

export const getCustomerInfo = createDebouncedAsyncThunk(
  'customer/getCustomerInfo',
  async (data: { bearerToken: string }) => await getCustomerInfoApi(data.bearerToken),
  1000
);

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCustomerInfo.pending, (state) => {
        state.customerDataStatus = 'loading';
      })
      .addCase(getCustomerInfo.fulfilled, (state, action) => {
        state.customerData = action.payload.data;
        state.customerDataStatus = 'succeeded';
      })
      .addCase(getCustomerInfo.rejected, (state, action) => {
        state.customerDataStatus = 'failed';
        state.error = action.error.message || 'Something went wrong';
      });
  },
});

export default customerSlice.reducer;
