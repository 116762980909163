import { setCookie, getCookie } from 'cookies-next';
import { IncomingMessage, ServerResponse } from 'http';
import { Session } from 'next-auth';

export const getAccountId = async (): Promise<string> => {
  return getEffectiveAccountId();
};

export const getBearerToken = (session: Session | null): string => {
  if (!session) {
    return '';
  }

  const user = session.user as Record<string, unknown>;
  return user.token as string;
};

export const saveEffectiveAccountId = (effectiveAccountId: string): void => {
  const now = new Date();
  const thirtyDaysFromNow = new Date(now.setDate(now.getDate() + 30));

  setCookie('acc-id', effectiveAccountId, {
    expires: thirtyDaysFromNow,
  });
};

export const deleteEffectiveAccountId = (req?: IncomingMessage, res?: ServerResponse): void => {
  const now = new Date();
  const yesterday = new Date(now.setDate(now.getDate() - 1));

  if (req != null && res != null) {
    setCookie('acc-id', '', {
      req,
      res,
      expires: yesterday,
    });
    return;
  }

  setCookie('acc-id', '', {
    expires: yesterday,
  });
};

export const getEffectiveAccountId = (): string => {
  const accountId = getCookie('acc-id');
  if (accountId == null) {
    return '';
  }

  return accountId as string;
};

export const getCheckoutAuthUrl = (returnUrl: string): string =>
  `/api/auth/login/myavid?returnUrl=${encodeURIComponent(returnUrl)}`;
