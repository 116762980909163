let featureConfig: Record<string, boolean> | null = null;

const getFeatures = (): Record<string, boolean> | null => {
  if (featureConfig != null) {
    return featureConfig;
  }
  try {
    const featureData = process.env.NEXT_PUBLIC_FEATURE_CONFIG;
    if (featureData == null) {
      return {};
    }

    featureConfig = {};
    const features = featureData.split(',');
    for (let i = 0; i < features.length; i++) {
      const feature = features[i].split('=');
      if (feature.length != 2) {
        continue;
      }

      const featureKey = feature[0];
      const featureValue = feature[1].toLowerCase() === 'true';

      featureConfig[featureKey] = featureValue;
    }

    return featureConfig;
  } catch (e) {
    console.error('Error occured', e);
    return {};
  }
};

export const isFeatureEnabled = (feature: string): boolean => {
  const features = getFeatures();
  if (features == null) {
    return false;
  }

  const isEnabled = features[feature];
  if (isEnabled == null) {
    return false;
  }

  return isEnabled;
};
