import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import createDebouncedAsyncThunk from 'lib/store/create-debounced-async-thunk';
import {
  ProductDetailsData,
  ProductPricingData,
  ProductVariationsData,
} from './model/product-models';
import {
  getProductTierDetails as getProductTierDetailsApi,
  getProductDetails as getProductDetailsApi,
  getProductPricing as getProductPricingApi,
} from './product-api';

interface InitialStateModel {
  productTierDetailsData: ProductVariationsData | null;
  productDetailsData: ProductDetailsData | null;
  productPricingData: ProductPricingData | null;
  productPricingStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  productDetailStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  productTierDetailStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: InitialStateModel = {
  productTierDetailsData: null,
  productDetailsData: null,
  productPricingData: null,
  productPricingStatus: 'idle',
  productDetailStatus: 'idle',
  productTierDetailStatus: 'idle',
  error: null,
};

export const getProductTierDetails = createDebouncedAsyncThunk(
  'products/getProductTierDetails',
  async (params: {
    productTierId: string;
    frequencyType: string;
    userType: string;
    currencyIsoCode: string;
  }) =>
    getProductTierDetailsApi(
      params.productTierId,
      params.frequencyType,
      params.userType,
      params.currencyIsoCode
    ),
  150
);

export const getProductDetails = createDebouncedAsyncThunk(
  'products/getProductDetails',
  async (productId: string) => getProductDetailsApi(productId),
  150
);

export const getProductPricing = createAsyncThunk(
  'products/getProductPricing',
  async (params: { productId: string; currencyIsoCode: string }) => getProductPricingApi(params)
);

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getProductTierDetails.pending, (state) => {
        state.productTierDetailStatus = 'loading';
      })
      .addCase(getProductTierDetails.fulfilled, (state, action) => {
        state.productTierDetailStatus = 'succeeded';
        state.productTierDetailsData = action.payload.data;
      })
      .addCase(getProductTierDetails.rejected, (state, action) => {
        state.productTierDetailStatus = 'failed';
        state.error = action.error.message || 'Something went wrong';
      })
      .addCase(getProductDetails.pending, (state) => {
        state.productDetailStatus = 'loading';
      })
      .addCase(getProductDetails.fulfilled, (state, action) => {
        state.productDetailStatus = 'succeeded';
        state.productDetailsData = action.payload.data;
      })
      .addCase(getProductDetails.rejected, (state, action) => {
        state.productDetailStatus = 'failed';
        state.error = action.error.message || 'Something went wrong';
      })
      .addCase(getProductPricing.pending, (state) => {
        state.productPricingStatus = 'loading';
      })
      .addCase(getProductPricing.fulfilled, (state, action) => {
        state.productPricingStatus = 'succeeded';
        state.productPricingData = action.payload.data;
      })
      .addCase(getProductPricing.rejected, (state, action) => {
        state.productPricingStatus = 'failed';
        state.error = action.error.message || 'Something went wrong';
      });
  },
});

export default productSlice.reducer;
