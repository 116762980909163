import { commonRequest, isNonOkResponse } from 'lib/common/common-http-client';
import { SfResponse } from 'lib/common/model/common-models';
import { PromotionData } from './models/promotion-models';

export const getLimitedTimeOffers = async (
  currencyIsoCode: string,
  cartId: string,
  bearerToken: string
): Promise<SfResponse<PromotionData[]>> => {
  const response = await commonRequest<SfResponse<PromotionData[]>>(
    'GET',
    '/promotions/limited-time-offer',
    null,
    {
      currencyIsoCode: currencyIsoCode,
      cartId: cartId,
    },
    bearerToken
  );

  if (isNonOkResponse(response)) {
    throw new Error('Failed to get LTOs!');
  }

  return response;
};
