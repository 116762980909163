import { createSlice } from '@reduxjs/toolkit';

interface CoveoQueryState {
  query: string | null;
  searchHub: string | null;
}

const initialState: CoveoQueryState = {
  query: null,
  searchHub: null,
};

const coveoQuerySlice = createSlice({
  name: 'CoveoQuery',
  initialState,
  reducers: {
    setCoveoQuery: (state, action) => {
      state.query = action.payload?.query;
      state.searchHub = action.payload?.searchHub;
    },
  },
});

export const { setCoveoQuery } = coveoQuerySlice.actions;
export default coveoQuerySlice.reducer;
