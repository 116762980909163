/*export const formatDuration = (durationNumber: number): string => {
  const date = new Date(0);
  date.setSeconds(durationNumber);
  const timeString = date?.toISOString().substring(11, 19);
  return timeString;
};*/

export const formatDurationFromMilliseconds = (totalMuliseconds: number): string => {
  const totalMinutes = Math.floor(totalMuliseconds / 60000);

  const seconds = totalMuliseconds % 60;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  let formattedDuration = `${minutes}:${pad(seconds, 2)}`;

  if (hours) {
    formattedDuration = `${hours}:${pad(minutes, 2)}:${pad(seconds, 2)}`;
  }

  return formattedDuration;
};

export const formatDurationFromSeconds = (totalSeconds: number): string => {
  const totalMinutes = Math.floor(totalSeconds / 60);

  const seconds = totalSeconds % 60;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  let formattedDuration = `${minutes}:${pad(seconds, 2)}`;
  if (hours) {
    formattedDuration = `${hours}:${pad(minutes, 2)}:${pad(seconds, 2)}`;
  }

  return formattedDuration;
};

const pad = (number: number, size: number): string => {
  let num = number.toString();
  while (num.length < size) {
    num = '0' + num;
  }
  return num;
};

export const addYears = (date: Date, years: number): Date => {
  date.setFullYear(date.getFullYear() + years);
  return date;
};

export const toLocalDateString = (date: Date): string => {
  const options: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'UTC',
  };
  const utcDate = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
  return new Intl.DateTimeFormat('en-US', options).format(utcDate);
};
