import { createSlice } from '@reduxjs/toolkit';
import { setLanguageName } from './navigation-utils';

interface InitialStateModel {
  languageSwitcherVisible: boolean;
  currentLanguage: string;
  menuVisible: boolean;
}

const initialState: InitialStateModel = {
  languageSwitcherVisible: false,
  currentLanguage: '',
  menuVisible: false,
};

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    toggleSwitcherModal(state) {
      state.languageSwitcherVisible = !state.languageSwitcherVisible;
    },
    changeLanguage(state, action) {
      state.currentLanguage = action.payload;
      setLanguageName(action.payload);
    },
    toggleMenuModal(state) {
      state.menuVisible = !state.menuVisible;
    },
  },
});
export const navigationActions = navigationSlice.actions;
export default navigationSlice.reducer;
