import { createSlice } from '@reduxjs/toolkit';
import createDebouncedAsyncThunk from 'lib/store/create-debounced-async-thunk';
import { PromotionData } from './models/promotion-models';
import { getLimitedTimeOffers as getLimitedTimeOffersApi } from './promotion-api';

interface InitialStateModel {
  limitedTimeOffers: PromotionData[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: InitialStateModel = {
  limitedTimeOffers: [],
  status: 'idle',
  error: null,
};

export const getLimitedTimeOffers = createDebouncedAsyncThunk(
  'promotions/getPromotions',
  async (data: { currencyIsoCode: string; cartId: string; bearerToken: string }) =>
    await getLimitedTimeOffersApi(data.currencyIsoCode, data.cartId, data.bearerToken),
  150
);

export const promotionSlice = createSlice({
  name: 'promotion',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getLimitedTimeOffers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getLimitedTimeOffers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.limitedTimeOffers = action.payload.data;
      })
      .addCase(getLimitedTimeOffers.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export default promotionSlice.reducer;
