import { createSlice } from '@reduxjs/toolkit';
import createDebouncedAsyncThunk from 'lib/store/create-debounced-async-thunk';
import { CurrencyCoversionRatesData, WebstoreData } from './model/webstore-models';
import {
  getWebstore as getWebstoreApi,
  getCurrencyConversionRates as getCurrencyConversionRatesApi,
} from './webstore-api';

interface InitialStateModel {
  webstoreData: WebstoreData;
  currencyConversionRatesData: CurrencyCoversionRatesData | null;
  webstoreStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const InitialWebstoreData: WebstoreData = {
  type: '',
  supportedLanguages: [],
  supportedCurrencies: [],
  defaltTaxLocaleType: '',
  defaultLanguage: '',
  defaultCurrencyIsoCode: '',
  name: '',
};

const initialState: InitialStateModel = {
  webstoreData: InitialWebstoreData,
  currencyConversionRatesData: null,
  webstoreStatus: 'idle',
  error: null,
};

export const getWebstore = createDebouncedAsyncThunk(
  'currency/getWebstore',
  async () => getWebstoreApi(),
  1000
);

export const getCurrencyConversionRates = createDebouncedAsyncThunk(
  'currency/getCurrencyConversionRates',
  async () => getCurrencyConversionRatesApi(),
  1000
);

export const webstoreSlice = createSlice({
  name: 'webstore',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getWebstore.pending, (state) => {
        state.webstoreStatus = 'loading';
      })
      .addCase(getWebstore.fulfilled, (state, action) => {
        state.webstoreStatus = 'succeeded';
        state.webstoreData = action.payload.data;
      })
      .addCase(getWebstore.rejected, (state, action) => {
        state.webstoreStatus = 'failed';
        state.error = action.error.message || 'Something went wrong';
      })
      .addCase(getCurrencyConversionRates.pending, (state) => {
        state.webstoreStatus = 'loading';
      })
      .addCase(getCurrencyConversionRates.fulfilled, (state, action) => {
        state.webstoreStatus = 'succeeded';
        state.currencyConversionRatesData = action.payload.data;
      })
      .addCase(getCurrencyConversionRates.rejected, (state, action) => {
        state.webstoreStatus = 'failed';
        state.error = action.error.message || 'Something went wrong';
      });
  },
});

export default webstoreSlice.reducer;
