import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import createDebouncedAsyncThunk from 'lib/store/create-debounced-async-thunk';
import { addCartItem, updateCartItem } from '../cart/cart-slice';
import {
  createCheckoutSession as createCheckoutSessionApi,
  getPaymentMethods as getPaymentMethodsApi,
  savePaymentMethod as savePaymentMethodApi,
  placeOrder as createOrderApi,
  updateCheckout as updateCheckoutApi,
} from './checkout-api';
import { deleteCheckoutId, saveCheckoutId } from './checkout-provider';
import { CheckoutData, Source } from './checkout-models';
import { isFeatureEnabled } from 'lib/feature-flags/feature-flags';

export interface InitialStateModel {
  checkoutData: CheckoutData | null;
  checkoutDataStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  paymentSessionId: string;
  paymentMethods: Source[];
  paymentMethodStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  selectedSource: CustomSource | null;
  createOrderStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  updateCheckoutStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  updateCheckoutError: string | null;
  checkoutId: string | null;
  checkoutStage:
    | 'sign-in'
    | 'payment-billing'
    | 'payment-method'
    | 'confirmation'
    | 'mp-paypal-billing';
  billingAddress: PaymentAddress | null;
  shippingAddress: PaymentAddress | null;
  error: string | null;
  defaultPaymentMethodId: string;
}

export type PaymentAddress = {
  firstName: string;
  lastName: string;
  line1: string;
  line2?: string;
  city?: string;
  state?: string;
  postalCode: string;
  country: string;
  phoneNumberExtension?: string;
  phoneNumber: string;
};

const initialState: InitialStateModel = {
  checkoutData: null,
  checkoutDataStatus: 'idle',
  paymentSessionId: '',
  paymentMethods: [],
  paymentMethodStatus: 'idle',
  selectedSource: null,
  createOrderStatus: 'idle',
  updateCheckoutStatus: 'idle',
  updateCheckoutError: null,
  checkoutId: null,
  checkoutStage: 'payment-billing',
  billingAddress: null,
  shippingAddress: null,
  error: null,
  defaultPaymentMethodId: '',
};

export const getPaymentMethods = createDebouncedAsyncThunk(
  'checkout/getPaymentMethods',
  async (data: { bearerToken: string }) => await getPaymentMethodsApi(data.bearerToken),
  150
);

export const savePaymentMethod = createDebouncedAsyncThunk(
  'checkout/savePaymentMethod',
  async (data: { sourceId: string; bearerToken: string }) =>
    await savePaymentMethodApi(data.sourceId, data.bearerToken),
  150
);

export const placeOrder = createDebouncedAsyncThunk(
  'checkout/placeOrder',
  async (data: { checkoutId: string; sourceId: string; cartId: string; bearerToken: string }) =>
    await createOrderApi(data.checkoutId, data.cartId, data.bearerToken),
  150
);

export const createCheckoutSession = createDebouncedAsyncThunk(
  'checkout/createCheckoutSession',
  async (data: { store: string; sourceId?: string; bearerToken: string }) =>
    await createCheckoutSessionApi(data.store, data?.sourceId, data.bearerToken),
  150
);

const isMultiCurrenciesPaymentMethodsEnabled = isFeatureEnabled(
  'multi-currency-payment-methods-SITECORE-34'
);

export const updateCheckout = createDebouncedAsyncThunk(
  'checkout/updateCheckout',
  async (data: {
    checkoutId: string;
    sourceId: string;
    bearerToken: string;
    currencyCode?: string;
    cartId?: string;
  }) => {
    if (isMultiCurrenciesPaymentMethodsEnabled) {
      return await updateCheckoutApi(
        data.checkoutId,
        data.sourceId,
        data.bearerToken,
        data.cartId,
        data.currencyCode
      );
    } else {
      return await updateCheckoutApi(data.checkoutId, data.sourceId, data.bearerToken);
    }
  },
  150
);

export type CustomSource = {
  source: Source;
  isNew: boolean;
  forceSave?: boolean;
};

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setSelectedSource: (state, action: PayloadAction<CustomSource | null>) => {
      state.updateCheckoutError = null;
      state.selectedSource = action.payload;
    },
    setBillingAddress: (state, action: PayloadAction<PaymentAddress | null>) => {
      state.billingAddress = action.payload;
    },
    setCheckoutStage: (
      state,
      action: PayloadAction<
        'payment-billing' | 'mp-paypal-billing' | 'payment-method' | 'confirmation'
      >
    ) => {
      state.checkoutStage = action.payload;
    },
    removeCheckoutSession: (state) => {
      state.updateCheckoutStatus = 'succeeded';
      state.checkoutData = null;
      state.checkoutDataStatus = 'idle';
      // state.paymentSessionId = action.payload.data?.digitalRiverResponse?.payment?.session?.id;
      state.checkoutId = null;
      deleteCheckoutId();
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createCheckoutSession.pending, (state) => {
        state.checkoutDataStatus = 'loading';
      })
      .addCase(createCheckoutSession.fulfilled, (state, action) => {
        state.checkoutData = action.payload.data;
        state.checkoutDataStatus = 'succeeded';
        state.paymentSessionId = action.payload.data?.digitalRiverResponse?.payment?.session?.id;

        state.checkoutId = action.payload.data?.digitalRiverResponse?.id;
        saveCheckoutId(action.payload.data?.digitalRiverResponse?.id);
      })
      .addCase(createCheckoutSession.rejected, (state, action) => {
        state.checkoutDataStatus = 'failed';
        state.error = action.error.message || 'Something went wrong';
      })
      .addCase(getPaymentMethods.pending, (state) => {
        state.paymentMethodStatus = 'loading';
      })
      .addCase(getPaymentMethods.fulfilled, (state, action) => {
        state.paymentMethodStatus = 'succeeded';
        if (action.payload.data?.digitalRiverResponse?.sources != null) {
          const sortedSources = action.payload.data.digitalRiverResponse.sources.sort((a, b) => {
            return new Date(a.createdTime).getTime() - new Date(b.createdTime).getTime();
          });
          state.paymentMethods = sortedSources;
          state.defaultPaymentMethodId = action.payload.data.digitalRiverResponse.defaultSourceId;
        }
      })
      .addCase(getPaymentMethods.rejected, (state, action) => {
        state.paymentMethodStatus = 'failed';
        state.error = action.error.message || 'Something went wrong';
      })
      .addCase(placeOrder.pending, (state) => {
        state.createOrderStatus = 'loading';
      })
      .addCase(placeOrder.fulfilled, (state) => {
        deleteCheckoutId();
        state.createOrderStatus = 'succeeded';
      })
      .addCase(placeOrder.rejected, (state, action) => {
        state.createOrderStatus = 'failed';
        state.error = action.error.message || 'Something went wrong';

        deleteCheckoutId();
        state.paymentSessionId = '';
        state.checkoutId = null;
        state.selectedSource = null;
      })
      .addCase(updateCheckout.pending, (state) => {
        state.checkoutDataStatus = 'loading';
        state.updateCheckoutStatus = 'loading';
        state.updateCheckoutError = null;
      })
      .addCase(updateCheckout.fulfilled, (state, action) => {
        if (action.payload == null) {
          return;
        }

        state.updateCheckoutStatus = 'succeeded';
        state.checkoutData = action.payload.data;
        state.checkoutDataStatus = 'succeeded';
        state.paymentSessionId = action.payload.data?.digitalRiverResponse?.payment?.session?.id;

        state.checkoutId = action.payload.data?.digitalRiverResponse?.id;
        saveCheckoutId(action.payload.data?.digitalRiverResponse?.id);
      })
      .addCase(updateCheckout.rejected, (state, action) => {
        state.updateCheckoutStatus = 'failed';
        state.checkoutDataStatus = 'idle';
        state.updateCheckoutError = action.error.message || 'Something went wrong';

        deleteCheckoutId();
        state.paymentSessionId = '';
        state.checkoutId = null;
        state.selectedSource = null;
      })
      .addCase(addCartItem.fulfilled, (state) => {
        state.checkoutId = null;
      })
      .addCase(updateCartItem.fulfilled, (state) => {
        state.checkoutId = null;
      });
  },
});

export const { setCheckoutStage, setBillingAddress, setSelectedSource, removeCheckoutSession } =
  checkoutSlice.actions;
export default checkoutSlice.reducer;
