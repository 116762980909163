import { commerceGet } from '../common/base-commerce-api';
import { SfResponse } from '../../common/model/common-models';
import { isNonOkResponse } from 'lib/common/common-http-client';
import { CustomerData } from './model/customer-models';

export const getCustomerInfo = async (bearerToken: string): Promise<SfResponse<CustomerData>> => {
  const response = await commerceGet<SfResponse<CustomerData>>(
    '/v1/customer/info',
    undefined,
    bearerToken
  );

  if (isNonOkResponse(response)) {
    if (response.status == 403) {
      throw new Error('You do not have access to the Apex class named: XC_CheckoutRestApi');
    }

    throw new Error('Failed to get customer info');
  }

  return response;
};
