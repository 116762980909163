import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import cartReducer from '../../components/cart/cart-slice';
import checkoutReducer from '../../components/checkout/checkout-slice';
import orderReducer from '../../components/order/order-slice';
import webstoreReducer from '../commerce/webstore/webstore-slice';
import promotionsReducer from '../promotions/promotion-slice';
import countriesReducer from '../commerce/countries/countries-slice';
import navigationReducer from '../navigation/navigation-slice';
import productReducer from '../commerce/product/product-slice';
import customerReducer from '../commerce/customer/customer-slice';
import coveoQuerySlice from 'components/search/coveo-query-slice';
//import { sitecoreSearchApi } from '../search/sitecore-search-api';

const store = configureStore({
  reducer: {
    cart: cartReducer,
    checkout: checkoutReducer,
    order: orderReducer,
    webstore: webstoreReducer,
    promotions: promotionsReducer,
    countries: countriesReducer,
    navigation: navigationReducer,
    product: productReducer,
    customer: customerReducer,
    coveoQuery: coveoQuerySlice,
    //[sitecoreSearchApi.reducerPath]: sitecoreSearchApi.reducer,
  },
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sitecoreSearchApi.middleware),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

setupListeners(store.dispatch);

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
