import {
  LanguagePair,
  NavigationGroup,
  NavigationItem,
  NavigationLink,
  NavigationMegaMenuGroup,
} from 'components/global-navigation/shop/navigation-props';
import { LanguageSetting } from 'components/language-currency-switcher/language-currency-switcher-props';
import { setCookie } from 'cookies-next';
import { addYears } from 'lib/utils/date-utils';

const locales: { [id: string]: string } = {};
locales['en'] = 'English';
locales['fr'] = 'Français';
locales['es'] = 'Español';
locales['de'] = 'Deutsch';
locales['zh'] = '中国人';
locales['ja'] = '日本';

export const getLanguages = (languages: LanguageSetting[]): LanguagePair[] => {
  const languageArray: LanguagePair[] = [];
  languages?.forEach((lang) =>
    languageArray.push({
      name:
        lang.languageDisplayNameOverride?.value !== ''
          ? lang.languageDisplayNameOverride.value
          : lang.languageReference?.targetItem?.displayName,
      code: lang.languageReference?.targetItem?.name,
    })
  );
  return languageArray;
};

export const getLanguageName = (locale: string): string => {
  //get language from current country
  const selectedKey = locales[locale];
  if (selectedKey) {
    return selectedKey;
  }
  //default language
  return locales.en;
};

//set cookie for locale
export const setLanguageName = (lang: string): void => {
  setCookie('NEXT_LOCALE', lang.toUpperCase(), {
    expires: addYears(new Date(), 1),
    domain: '.avid.com',
  });
};

export const geoRestrictData = (country: string, navItems: NavigationItem[]): NavigationItem[] => {
  const tmpMenuItems: NavigationItem[] = JSON.parse(JSON.stringify(navItems));
  const groups: NavigationItem[] = [];
  for (let i = 0; i < tmpMenuItems?.length; i++) {
    const tmpGroup = tmpMenuItems[i];
    const group = geoRestrictDataNavigationGroup(country, tmpGroup);
    groups.push(group);
  }

  return groups;
};

export const geoRestrictDataNavigationGroup = (
  country: string,
  navGroup: NavigationItem
): NavigationItem => {
  const group: NavigationItem = JSON.parse(JSON.stringify(navGroup));

  let groupNavItems: NavigationItem[] = [];
  if ((group as NavigationMegaMenuGroup).mega) {
    const tmpGroup = group as NavigationMegaMenuGroup;
    groupNavItems = tmpGroup.children.results;
  } else if ((group as NavigationGroup).group) {
    const tmpGroup = group as NavigationGroup;
    groupNavItems = tmpGroup.children.results;
  }

  const groupItems = geoRestrictDataNavigationItems(country, groupNavItems);
  (group as NavigationGroup).children.results = groupItems;

  return group;
};

export const geoRestrictDataNavigationItems = (
  country: string,
  navItems: NavigationItem[]
): NavigationItem[] => {
  const groupItems: NavigationItem[] = [];
  for (let j = 0; j < navItems.length; j++) {
    const navItem = navItems[j] as NavigationLink;
    if (navItem.visibleForCountry == null) {
      groupItems.push(navItem);
      continue;
    }

    if (navItem.visibleForCountry.value != country) {
      continue;
    }

    groupItems.push(navItem);
  }

  return groupItems;
};
