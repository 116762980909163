import { commonGatewayRequest, isNonOkResponse } from 'lib/common/common-http-client';
import { SfResponse } from 'lib/common/model/common-models';
import { commerceGet } from '../common/base-commerce-api';
import { CurrencyCoversionRatesData, WebstoreData } from './model/webstore-models';

export const getWebstore = async (): Promise<SfResponse<WebstoreData>> => {
  const response = await commonGatewayRequest<SfResponse<WebstoreData>>(
    'GET',
    '/webstore/info',
    undefined,
    undefined,
    ''
  );

  if (isNonOkResponse(response)) {
    throw new Error('Failed to get webstore data!');
  }

  return response;
};

export const getCurrencyConversionRates = async (): Promise<
  SfResponse<CurrencyCoversionRatesData>
> => {
  const response = await commerceGet<SfResponse<CurrencyCoversionRatesData>>(
    '/v1/webstore/currency-rates',
    undefined,
    ''
  );

  if (isNonOkResponse(response)) {
    throw new Error('Failed to get webstore data!');
  }

  return response;
};
