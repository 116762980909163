import { getBearerToken } from 'lib/authentication/account-provider';
import { getCustomerInfo } from 'lib/commerce/customer/customer-slice';
import { CustomerData } from 'lib/commerce/customer/model/customer-models';
import { pushCoreDataLayer } from 'lib/google-analytics/core-datalayer';
import { pushVirtualPageView } from 'lib/google-analytics/virtual-page-view';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { useSession } from 'next-auth/react';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo } from 'react';

const CoreDataLayer = (props: { locale: string; pageTitle: string }): JSX.Element => {
  const { data: session, status } = useSession();
  const bearerToken = useMemo(() => getBearerToken(session), [session]);
  const dispatch = useAppDispatch();
  const router = useRouter();
  const pathName = usePathname();
  const customerInfo = useAppSelector((state) => state.customer.customerData);
  const isLoggedIn = session != null;
  const currencyIsoCode = useAppSelector((state) => state.cart.currencyIsoCode);
  let publicUrl: string = process.env.PUBLIC_URL ?? '';

  if (publicUrl.endsWith('/')) {
    publicUrl = publicUrl.substring(0, publicUrl.length - 2);
  }

  const fullUrlPath = publicUrl + router.asPath;

  useEffect(() => {
    if (status === 'loading') {
      return;
    }
    if (currencyIsoCode) {
      if (isLoggedIn) {
        if (!customerInfo) {
          dispatch(getCustomerInfo({ bearerToken: bearerToken }));
        } else {
          pushCoreDataLayer(
            'yes',
            currencyIsoCode as string,
            props.locale,
            customerInfo as CustomerData
          );
        }
      } else {
        pushCoreDataLayer('no', currencyIsoCode as string, props.locale);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerInfo, currencyIsoCode, status]);

  useEffect(() => {
    pushVirtualPageView(pathName, props.pageTitle, fullUrlPath);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathName]);

  return <></>;
};

export default CoreDataLayer;
