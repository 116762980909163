import { setCookie, getCookie, CookieValueTypes } from 'cookies-next';
import { IncomingMessage, ServerResponse } from 'http';
import { Source } from './checkout-models';

export const getCheckoutId = (): string => {
  const checkoutId = getCookie('checkout-id');
  if (checkoutId == null) {
    return '';
  }

  return checkoutId as string;
};

export const saveCheckoutId = (checkoutId: string): void => {
  setCookie('checkout-id', checkoutId);
};

export const deleteCheckoutId = (): void => {
  const now = new Date();
  const yesterday = new Date(now.setDate(now.getDate() - 1));
  setCookie('checkout-id', '', { expires: yesterday });
};

export const saveExpressPayment = (source: Source): void => {
  const sourceJson = JSON.stringify(source);
  setCookie('express-payment', sourceJson);
};

export const removeSavedExpressPayment = (): void => {
  const now = new Date();
  const yesterday = new Date(now.setDate(now.getDate() - 1));
  setCookie('express-payment', '', { expires: yesterday });
};

export const getSavedExpressPayment = (
  req?: IncomingMessage,
  res?: ServerResponse
): Source | null => {
  let expressPaymentCookie: CookieValueTypes;
  if (req !== null && res !== null) {
    expressPaymentCookie = getCookie('express-payment', { req: req, res: res });
  } else {
    expressPaymentCookie = getCookie('express-payment');
  }
  if (expressPaymentCookie == null) {
    return null;
  }

  const expressPaymentJson = expressPaymentCookie as string;
  if (expressPaymentJson == null || expressPaymentJson.length <= 0) {
    return null;
  }

  return JSON.parse(expressPaymentJson) as Source;
};

export const basePaymentDropinConfiguration = {
  sessionId: '',
  options: {
    flow: 'checkout',
    showComplianceSection: false,
    showSavePaymentAgreement: true,
    showTermsOfSaleDisclosure: false,
    button: {
      type: 'continue',
    },
    usage: 'convenience',
  },
  billingAddress: {},
  paymentMethodConfiguration: {
    style: {
      base: {
        fontFamily:
          'GT Walsheim Avid,apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif',
      },
      invalid: {
        color: '#dc3545',
        ':-webkit-autofill': {
          color: '#dc3545',
        },
      },
      complete: {
        color: '#28a745',
        ':hover': {
          color: '#28a745',
        },
        ':-webkit-autofill': {
          color: '#28a745',
        },
      },
      empty: {
        color: '#000000',
      },
    },
    payPal: {
      style: {
        label: 'checkout',
        size: 'responsive',
        color: 'gold',
        shape: 'pill',
        layout: 'horizontal',
        fundingicons: 'false',
        tagline: 'false',
      },
    },
    creditCard: {
      cardNumberPlaceholderText: 'Card Number',
      cardExpirationPlaceholderText: 'MM/YY',
      cardCvvPlaceholderText: 'CVV',
      style: {
        base: {
          color: '#222222',
          fontSize: '1rem',
          height: '38px',
          paddingLeft: '12px',
          paddingRight: '12px',
          '::placeholder': {
            color: '#737577',
          },
        },
      },
      mask: true,
    },
    googlePay: {
      style: {
        buttonType: 'plain',
        buttonColor: 'dark',
        buttonLanguage: 'en',
      },
    },
    enabledPaymentMethods: ['creditCard', 'payPal', 'payPalBilling', 'googlePay'],
  },
};
