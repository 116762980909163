import {
  Price,
  ProductDetailsData,
  ProductPricingData,
  ProductVariationsData,
} from './model/product-models';
import { SfResponse } from '../../common/model/common-models';
import { commonGatewayRequest, isNonOkResponse } from 'lib/common/common-http-client';
import { Product } from 'src/pages/api/product/models/product';
import { stringify } from 'querystring';

export const getSitecoreProductDetails = async (
  productId: string,
  language: string
): Promise<Product[] | null> => {
  const queryParams: Record<string, string> = {
    productId,
    language,
  };

  const queryParamsString = stringify(queryParams);
  const response = await fetch(`/api/product/details?${queryParamsString}`, {
    method: 'GET',
  });

  if (!response.ok) {
    return null;
  }

  const responseData = await response.text();

  try {
    const data = JSON.parse(responseData);
    const result = data as Product[];
    return result;
  } catch {
    //may need to change later
    return responseData as unknown as Product[];
  }
};

export const getGraphQlProductDetails = async (
  productId: string,
  language: string
): Promise<Product[] | null> => {
  const queryParams: Record<string, string> = {
    productId,
    language,
  };

  const queryParamsString = stringify(queryParams);
  const response = await fetch(`/api/product/details?${queryParamsString}`, {
    method: 'GET',
  });

  if (!response.ok) {
    return null;
  }

  const responseData = await response.text();

  try {
    const data = JSON.parse(responseData);
    const result = data as Product[];
    return result;
  } catch {
    //may need to change later
    return responseData as unknown as Product[];
  }
};

export const getMultiProductPricing = async (
  productIds: Array<string>,
  currencyIsoCode: string
): Promise<SfResponse<Array<Price>>> => {
  const response = await commonGatewayRequest<SfResponse<Array<Price>>>(
    'POST',
    '/product/price',
    {
      currencyIsoCode: currencyIsoCode,
      productIds: productIds,
    },
    undefined,
    ''
  );

  if (isNonOkResponse(response)) {
    throw new Error('Failed to get product details!');
  }

  return response;
};

export const getProductTierDetails = async (
  productTableId: string,
  frequencyType: string,
  userType: string,
  currencyIsoCode: string
): Promise<SfResponse<ProductVariationsData>> => {
  const response = await commonGatewayRequest<SfResponse<ProductVariationsData>>(
    'GET',
    '/product/tier/v1',
    null,
    {
      productTierId: productTableId,
      subscriptionType: frequencyType,
      userType: userType,
      currencyIsoCode: currencyIsoCode,
    },
    ''
  );

  if (isNonOkResponse(response)) {
    throw new Error('Failed to get product details!');
  }

  return response;
};

export const getProductDetails = async (
  productId: string
): Promise<SfResponse<ProductDetailsData>> => {
  const response = await commonGatewayRequest<SfResponse<ProductDetailsData>>(
    'GET',
    '/product/detail',
    null,
    {
      productId: productId,
    },
    ''
  );

  if (isNonOkResponse(response)) {
    throw new Error('Failed to get product details!');
  }

  return response;
};

export const getProductPricing = async (params: {
  productId: string;
  currencyIsoCode: string;
}): Promise<SfResponse<ProductPricingData>> => {
  const response = await commonGatewayRequest<SfResponse<ProductPricingData>>(
    'GET',
    '/product/price',
    null,
    params,
    ''
  );

  if (isNonOkResponse(response)) {
    throw new Error('Failed to get product pricing!');
  }

  return response;
};

export const getProductVariations = async (params: {
  productId: string;
  currencyIsoCode: string;
}): Promise<SfResponse<ProductVariationsData>> => {
  const response = await commonGatewayRequest<SfResponse<ProductVariationsData>>(
    'GET',
    '/productvariation/detail',
    null,
    params,
    ''
  );

  if (isNonOkResponse(response)) {
    throw new Error('Failed to get product variations!');
  }

  return response;
};
