import { setCookie, getCookie, hasCookie, CookieValueTypes } from 'cookies-next';
import { IncomingMessage, ServerResponse } from 'http';
import { NextRequest, NextResponse } from 'next/server';

type ServerSideCookieContext = {
  req?: IncomingMessage;
  res?: ServerResponse;
  nextRequest?: NextRequest;
  nextResponse?: NextResponse;
};

export const hasStoredCurrency = (): boolean => {
  return hasCookie('currency');
};

export const hasStoredDefaultCurrency = (): boolean => {
  return hasCookie('defaultCurrency');
};

export const getStoredCurrency = (): string => {
  const currency = getCookie('currency');
  if (currency == null) {
    return '';
  }

  return currency as string;
};

export const getStoredDefaultCurrency = (): string | null => {
  if (process.env.NODE_ENV == 'development') {
    const storedCurrency = getStoredCurrency();
    if (storedCurrency != null && storedCurrency !== '') {
      return null;
    }

    return 'USD';
  }

  const currency = getCookie('defaultCurrency');
  if (currency == null) {
    return null;
  }

  return currency as string;
};

export const setStoredCurrency = (
  currencyIso: string,
  serverSideCookieContext?: ServerSideCookieContext
): void => {
  if (serverSideCookieContext?.req !== null && serverSideCookieContext?.res !== null) {
    setCookie('currency', currencyIso, {
      req: serverSideCookieContext?.req,
      res: serverSideCookieContext?.res,
      domain: process.env.NODE_ENV != 'development' ? '.avid.com' : undefined,
    });
  } else if (
    serverSideCookieContext?.nextRequest !== null &&
    serverSideCookieContext?.nextResponse !== null
  ) {
    serverSideCookieContext?.nextResponse?.cookies.set('currency', currencyIso);
  } else {
    setCookie('currency', currencyIso, {
      domain: process.env.NODE_ENV != 'development' ? '.avid.com' : undefined,
    });
  }
};

export const deleteStoredDefaultCurrency = (req?: IncomingMessage, res?: ServerResponse): void => {
  const now = new Date();
  const yesterday = new Date(now.setDate(now.getDate() - 1));
  if (req !== null && res !== null) {
    setCookie('defaultCurrency', '', {
      req,
      res,
      expires: yesterday,
    });
  }
  setCookie('defaultCurrency', '', {
    expires: yesterday,
  });
};

export const getCartId = (): string => {
  return getStoredCartId();
};

export const setStoredCartId = (cartId: string): void => {
  setCookie('cart-id', cartId);
};

export const getStoredCartId = (req?: IncomingMessage, res?: ServerResponse): string => {
  let storedCartId: CookieValueTypes;
  if (req !== null && res !== null) {
    storedCartId = getCookie('cart-id', { req: req, res: res });
  } else {
    storedCartId = getCookie('cart-id');
  }
  if (storedCartId == null) {
    return '';
  }

  return storedCartId as string;
};

export const deleteStoredCartId = (req: IncomingMessage, res: ServerResponse): void => {
  const now = new Date();
  const yesterday = new Date(now.setDate(now.getDate() - 1));

  setCookie('cart-id', '', {
    req,
    res,
    expires: yesterday,
  });
};

export const setStoredGuestCartId = (
  cartId: string,
  req: IncomingMessage,
  res: ServerResponse
): void => {
  const now = new Date();
  const thirtyDaysFromNow = new Date(now.setDate(now.getDate() + 30));

  setCookie('guest-cart-id', cartId, {
    expires: thirtyDaysFromNow,
    req: req,
    res: res,
  });
};

export const getStoredGuestCartId = (): string => {
  const storedGuestCartId = getCookie('guest-cart-id');
  if (storedGuestCartId == null) {
    return '';
  }

  return storedGuestCartId as string;
};

export const deleteStoredGuestCartId = (req?: IncomingMessage, res?: ServerResponse): void => {
  const now = new Date();
  const yesterday = new Date(now.setDate(now.getDate() - 1));
  if (req !== null && res !== null) {
    setCookie('guest-cart-id', '', {
      req,
      res,
      expires: yesterday,
    });
  }
  setCookie('guest-cart-id', '', {
    expires: yesterday,
  });
};
