import { saveEffectiveAccountId } from 'lib/authentication/account-provider';
import {
  commerceGet,
  commercePost,
  commerceDelete,
  commercePatch,
} from '../../lib/commerce/common/base-commerce-api';
import { SfResponse } from '../../lib/common/model/common-models';
import {
  deleteStoredGuestCartId,
  getCartId,
  getStoredGuestCartId,
  setStoredCartId,
} from './cart-provider';
import {
  CartItemDetails,
  CartSummary,
  changeCurrencyData,
  CreateCartData,
  GetCartItemsData,
  CouponDetails,
} from './cart-models';
import { isNonOkResponse } from 'lib/common/common-http-client';

export const createCart = async (bearerToken: string): Promise<SfResponse<CreateCartData>> => {
  const response = await commercePost<SfResponse<CreateCartData>>(
    '/cart/create',
    {
      type: 'Cart',
      isSecondary: false,
      name: 'Test Cart',
    },
    undefined,
    bearerToken
  );

  if (isNonOkResponse(response)) {
    throw new Error('Failed to create cart!');
  }

  const accountId = response.data.accountId;
  saveEffectiveAccountId(accountId);

  return response;
};

export const getCartItems = async (bearerToken: string): Promise<SfResponse<GetCartItemsData>> => {
  const queryParams: Record<string, string> = {
    cartId: getCartId(),
  };

  const response = await commerceGet<SfResponse<GetCartItemsData>>(
    '/cart/get-items',
    queryParams,
    bearerToken
  );

  if (isNonOkResponse(response)) {
    if (response.status == 401) {
      //UnAuthorized
      throw new Error('User is unauthorized');
    }

    throw new Error('Failed to get cart items!');
  }

  const accountId = response.data.cartSummary.accountId;
  saveEffectiveAccountId(accountId);

  const cartId = response.data.cartSummary.cartId;
  setStoredCartId(cartId);

  return response;
};

export const addCartItem = async (
  sku: string,
  quantity: number,
  bearerToken: string
): Promise<SfResponse<CartItemDetails>> => {
  const response = await commercePost<SfResponse<CartItemDetails>>(
    '/cart/add-item',
    {
      cartId: getCartId(),
      quantity: quantity,
      productId: sku,
    },
    undefined,
    bearerToken
  );

  if (isNonOkResponse(response)) {
    throw new Error('Failed to create cart!');
  }

  return response;
};

export const updateCartItem = async (
  cartItemId: string,
  quantity: number,
  bearerToken: string
): Promise<SfResponse<CartItemDetails | void>> => {
  const response = await commercePatch<SfResponse<CartItemDetails | void>>(
    '/cart/update-item',
    {
      cartId: getCartId(),
      quantity: quantity,
      cartItemId,
    },
    undefined,
    bearerToken
  );

  if (isNonOkResponse(response)) {
    throw new Error('Failed to update item!');
  }

  return response;
};

export const deleteCartItem = async (
  cartItemId: string,
  bearerToken: string
): Promise<SfResponse<string>> => {
  const response = await commerceDelete<SfResponse<string>>(
    '/cart/update-item',
    {},
    { cartId: getCartId(), cartItemId },
    bearerToken
  );

  if (isNonOkResponse(response)) {
    throw new Error('Failed to delete item!');
  }

  return response;
};

export const getCartSummary = async (bearerToken: string): Promise<SfResponse<CartSummary>> => {
  const queryParams: Record<string, string> = {
    cartId: getCartId(),
  };

  const guestCartId = getStoredGuestCartId();
  if (guestCartId !== '') {
    queryParams.guestCartId = guestCartId;
  }

  const response = await commerceGet<SfResponse<CartSummary>>(
    '/cart/summary',
    queryParams,
    bearerToken
  );

  if (isNonOkResponse(response)) {
    if (response.status == 401) {
      //UnAuthorized
      throw new Error('User is unauthorized');
    }
    throw new Error('Failed to get cart items!');
  }

  const accountId = response.data.accountId;
  saveEffectiveAccountId(accountId);

  const cartId = response.data.cartId;
  setStoredCartId(cartId);

  if (guestCartId !== '') {
    deleteStoredGuestCartId();
  }

  return response;
};

export const changeCurrency = async (
  currencyIsoCode: string,
  bearerToken: string
): Promise<SfResponse<changeCurrencyData>> => {
  const response = await commercePatch<SfResponse<changeCurrencyData>>(
    '/cart/currency',
    {
      currencyIsoCode,
      cartId: getCartId(),
    },
    undefined,
    bearerToken
  );

  if (isNonOkResponse(response)) {
    throw new Error('Failed to change currency!');
  }

  return response;
};

export const applyCoupon = async (
  couponCode: string,
  bearerToken: string
): Promise<SfResponse<CouponDetails>> => {
  const response = await commercePost<SfResponse<CouponDetails>>(
    '/cart/coupons',
    {
      cartId: getCartId(),
      couponCode,
    },
    undefined,
    bearerToken
  );

  if (isNonOkResponse(response)) {
    throw new Error(`Failed to apply coupon`);
  }

  return response;
};

export const removeCoupon = async (
  cartCouponId: string,
  bearerToken: string
): Promise<SfResponse<CouponDetails>> => {
  const response = await commerceDelete<SfResponse<CouponDetails>>(
    '/cart/coupons',
    {
      cartId: getCartId(),
      cartCouponId,
    },
    undefined,
    bearerToken
  );

  if (isNonOkResponse(response)) {
    throw new Error(`Failed to apply coupon`);
  }

  return response;
};
